import React, { useState } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import uniqueId from "lodash/uniqueId";
import IsrButton from "../IsrButton/IsrButton";
import { useTwitterIds } from "../../hooks/useTweeterIds/useTweeterIds";
import "./RecentTweets.scss";
import { useHomePageTweetsSection } from "../../hooks/useHomePageTweetsSection/useHomePageTweetsSection";

const RecentTweets = () => {
  const [loader, setLoader] = useState(true);
  const twitterIds = useTwitterIds();

  const handleTweetLoad = () => {
    if (loader) {
      setLoader(false);
    }
  };

  const { heading, copy, manualTwitterIds } = useHomePageTweetsSection();
  const mergedTwitterIds = [
    ...(twitterIds.length ? twitterIds : []),
    ...manualTwitterIds.map(({ twitterPostId }) => twitterPostId),
  ];
  return (
    <section className="recent-tweets">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2 col-xl-10 offset-xl-1">
            <div className="text-wrapper">
              {heading && <h2>{heading}</h2>}
              {copy && (
                <div className="row">
                  <div className="col-md-2" />
                  <div
                    className="col-md-8 content-wrapper"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: copy,
                    }}
                  />
                  <div className="col-md-2" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row tweets-container">
          {loader && (
            <div className="col-12">
              <div className="twitter-item -loader">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          )}
          {mergedTwitterIds.slice(0, 3).map((tweet) => (
            <div key={uniqueId("hp_rtwe_")} className="col-12 col-lg-4">
              <div className="twitter-item">
                <TwitterTweetEmbed
                  tweetId={tweet}
                  options={{
                    cards: "hidden",
                    conversation: "none",
                    height: 400,
                    align: "center",
                  }}
                  onLoad={handleTweetLoad}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2 col-xl-10 offset-xl-1">
            <div className="btn-wrapper">
              <IsrButton type="button" variant="primary" size="md">
                <a
                  href="https://twitter.com/NYASciences"
                  target="_blank"
                  rel="noreferrer"
                >
                  Follow Us on Twitter
                </a>
              </IsrButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecentTweets;
