import React from "react";
import { useCookies } from "react-cookie";
import { useHomePageInfoMsg } from "../../../hooks/useHomePageInfoMsg/useHomePageInfoMsg";
import "./InformationalMessage.scss";

const InformationalMessage = () => {
  const msgData = useHomePageInfoMsg();
  const [cookies, setCookie] = useCookies();
  const setCookieHandler = () => {
    setCookie("informational_message", true);
  };

  return (
    <>
      {!cookies.informational_message && msgData && (
        <div className="informational-message">
          <div className="container">
            <p>{msgData}</p>
            <button type="button" onClick={setCookieHandler}>
              <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                <g stroke="#000" strokeWidth="2" fill="none" fillRule="evenodd">
                  <path d="m1 1 6 6-6 6M13 1 7 7l6 6" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InformationalMessage;
