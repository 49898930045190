import React from "react";
import PropTypes from "prop-types";
import PromoSlotCard from "./components/promoSlotsCard/PromoSlotCard";
import PromoSlotCardCustomContent from "./components/promoSlotCardCustomContent/promoSlotCardCustomContent";
import "./PromoSlotsSection.scss";

const PromoSlotsSection = ({ data: { heading, copy, choosePromoSlots } }) => {
  return (
    choosePromoSlots?.length > 0 && (
      <section
        className={`promoSlotsSection ${
          heading && copy ? "with-heading" : "no-heading"
        }`}
      >
        <div className="container">
          <div className="promoSlotsSection-upperPart">
            {heading && <h2>{heading}</h2>}
            {copy && (
              <div className="row">
                <div className="col-md-2" />
                <div
                  className="col-md-8 content-wrapper"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: copy,
                  }}
                />
                <div className="col-md-2" />
              </div>
            )}
          </div>
          <div className="cardsContainer">
            {choosePromoSlots.map((promoSlot) => {
              const slotType =
                promoSlot.promoSlotsFields.promoSlotsGroup.promoSlotType;
              const content =
                promoSlot.promoSlotsFields.promoSlotsGroup.chooseBlogPost;
              if (slotType === "blog_post" && content) {
                return <PromoSlotCard key={promoSlot.id} post={content} />;
              }
              if (slotType === "custom") {
                return (
                  <PromoSlotCardCustomContent
                    key={promoSlot.id}
                    data={promoSlot.promoSlotsFields.promoSlotsGroup.content}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      </section>
    )
  );
};

PromoSlotsSection.defaultProps = {
  data: {},
};

PromoSlotsSection.propTypes = {
  data: PropTypes.object,
};

export default PromoSlotsSection;
