import * as React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import InformationalMessage from "../components/HomePage/InformationalMessage/InformationalMessage";
import IsrSlider from "../components/HomePage/Slider/IsrSlider";
import RecentTweets from "../components/RecentTweets/RecentTweets";
import PromoSlotsSection from "../components/PromoSlotsSection/PromoSlotsSection";
import Footer from "../components/Footer/Footer";
import FeaturedCrisis from "../components/HomePage/FeaturedCrisis/FeaturedCrisis";
import { useHomepagePromoSlots } from "../hooks/useHomepagePromoSlots/useHomepagePromoSlots";

const IndexPage = () => {
  const promoSlotsData = useHomepagePromoSlots();
  return (
    <Layout>
      <Header />
      <InformationalMessage />
      <IsrSlider />
      <FeaturedCrisis />
      <RecentTweets />
      <PromoSlotsSection data={promoSlotsData} />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
