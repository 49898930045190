import { graphql, useStaticQuery } from "gatsby";

export const useFeaturedCrisis = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Homepage" } }) {
        homepageFields {
          featuredCrisis {
            copy
            headline
            selectCrisis {
              ... on WpCrisis {
                id
                uri
                crisis {
                  crisisStatus
                  crisisStatusHoverText
                  headingSection {
                    heading
                    backgroundImage {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            transformOptions: { cropFocus: CENTER }
                          )
                        }
                      }
                      altText
                    }
                  }
                }
              }
            }
            showSubmitAProposal
          }
        }
      }
    }
  `);
  return data.wpPage.homepageFields.featuredCrisis;
};
