import { useStaticQuery, graphql } from "gatsby";
export const useHomePageSlider = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(isFrontPage: { eq: true }) {
        id
        homepageFields {
          headerSlider {
            slides {
              slide {
                copy
                heading
                link {
                  target
                  title
                  url
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        height: 440
                        width: 1920
                        transformOptions: { cropFocus: CENTER }
                      )
                    }
                  }
                  altText
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.homepageFields.headerSlider;
};
