import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import IsrLink from "../../../../components/IsrLink/IsrLink";
import IsrButton from "../../../IsrButton/IsrButton";
import "./promoSlotCardCustomContent.scss";

const PromoSlotCardCustomContent = ({ data: { copy, image, link } }) => {
  return (
    <div
      className={`promoSlotCard promoSlotCardCustomContent ${
        image ? "" : "-no-img"
      }`}
    >
      <div className="promoSlotCardWrapper">
        {copy && (
          <div className="upperCardPart">
            <div
              className="post-excerpt"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
          </div>
        )}
        {image && (
          <div className="image-card-part">
            <IsrLink link={link}>
              <GatsbyImage
                image={image?.localFile?.childImageSharp?.gatsbyImageData}
                alt={image.altText}
                className={`slotCardImage ${copy ? "" : "-no-copy"}`}
              />
            </IsrLink>
          </div>
        )}

        <IsrLink link={link}>
          <IsrButton variant="primary" type="button" size="md">
            {link.title}
          </IsrButton>
        </IsrLink>
      </div>
    </div>
  );
};

export default PromoSlotCardCustomContent;
