import React from "react";

const PrevArrow = ({ className, style, onClick }) => (
  <button
    style={{
      ...style,
      left: 16,
      zIndex: 1001,
      opacity: 1,
    }}
    onClick={onClick}
    className={className}
  >
    <div>back</div>
  </button>
);

export default PrevArrow;
