import React from "react";

const NextArrow = ({ className, style, onClick }) => (
  <button
    style={{ ...style, right: 60, opacity: 1, zIndex: 1001 }}
    onClick={onClick}
    className={className}
  >
    <div>next</div>
  </button>
);

export default NextArrow;
