import React from "react";
import propTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import IsrLabel from "../../../IsrLabel/IsrLabel";
import IsrButton from "../../../IsrButton/IsrButton";
import "./PromoSlotCard.scss";

const PromoSlotCard = ({
  post: { categories, author, featuredImage, uri, excerpt, title, date },
}) => {
  return (
    <div className="promoSlotCard">
      <div className="promoSlotCardWrapper">
        <div className="upperCardPart">
          {categories?.nodes.slice(0, 3).map((category) => {
            return (
              <Link key={category.id} to={category.uri}>
                <IsrLabel variant="secondary" color="grey" size="md">
                  {category.name}
                </IsrLabel>
              </Link>
            );
          })}

          <div
            className="post-excerpt"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: excerpt,
            }}
          />
        </div>
        <div className="image-card-part">
          <Link to={uri}>
            {featuredImage && (
              <GatsbyImage
                image={
                  featuredImage.node.localFile.childImageSharp.gatsbyImageData
                }
                alt={
                  author?.node.name
                    ? author.node.name
                    : `${author.node.firstName} ${author.node.lastName}`
                }
                className="slotCardImage"
              />
            )}
          </Link>
        </div>

        <div className="bottomCardPart">
          <div className="cardInfo">
            <p>{date}</p>

            <Link to={author?.node.uri}>{author?.node.name}</Link>
          </div>
          <Link to={uri}>
            <IsrButton variant="arrow" type="button" size="md" />
          </Link>
        </div>
      </div>
    </div>
  );
};

PromoSlotCard.propTypes = {
  post: propTypes.shape({
    date: propTypes.string.isRequired,
    uri: propTypes.string.isRequired,
    categories: propTypes.shape({
      nodes: propTypes.arrayOf(
        propTypes.shape({
          map: propTypes.func,
          uri: propTypes.string,
          name: propTypes.string,
        })
      ).isRequired,
    }).isRequired,
    excerpt: propTypes.string.isRequired,
    featuredImage: propTypes.shape({
      node: propTypes.shape({
        localFile: propTypes.shape({
          childImageSharp: propTypes.shape({
            gatsbyImageData: propTypes.object.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    author: propTypes.shape({
      node: propTypes.shape({
        uri: propTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
};

export default PromoSlotCard;
