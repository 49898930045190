import { useStaticQuery, graphql } from "gatsby";

export const useHomePageTweetsSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(isFrontPage: { eq: true }) {
        homepageFields {
          tweetsSection {
            copy
            heading
            manualTwitterIds {
              twitterPostId
            }
          }
        }
      }
    }
  `);
  return data.wpPage.homepageFields.tweetsSection;
};
