import React from "react";
import Slider from "react-slick";
import uniqueId from "lodash/uniqueId";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useHomePageSlider } from "../../../hooks/useHomePageSlider/useHomePageSlider";
import IsrButton from "../../IsrButton/IsrButton";
import PrevArrow from "./components/PrevArrow";
import NextArrow from "./components/NextArrow";
import "./IsrSlider.scss";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  arrows: true,
  autoplay: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

const IsrSlider = () => {
  const sliderData = useHomePageSlider();
  const slides = sliderData.slides.map((item) => {
    return (
      <div className="isr-slide" key={uniqueId("isr_slide_")}>
        <GatsbyImage
          image={item.slide.image?.localFile.childImageSharp.gatsbyImageData}
          alt={
            item.slide.image?.altText
              ? item.slide.image.altText
              : item.slide.heading
          }
        />
        <div className="slide-content">
          {item.slide.heading && (
            <h2 dangerouslySetInnerHTML={{ __html: item.slide.heading }} /> // eslint-disable-line react/no-danger
          )}
          {item.slide.copy && <p>{item.slide.copy}</p>}
          {item.slide.link && (
            <IsrButton type="button" variant="primary" size="md">
              {item.slide.link.url.includes("http") ? (
                <a
                  href={item.slide.link.url}
                  target={`${item.slide.target ? item.slide.target : ""}`}
                >
                  {item.slide.link.title}
                </a>
              ) : (
                <Link
                  to={item.slide.link.url}
                  target={`${item.slide.target ? item.slide.target : ""}`}
                >
                  {item.slide.link.title}
                </Link>
              )}
            </IsrButton>
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="homepage-slider">
      <div className="container">
        <div className="text-wrapper">
          <h2 className="subtitle">Established in 2022</h2>
        </div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...sliderSettings}>{slides}</Slider>
      </div>
    </div>
  );
};

export default IsrSlider;
