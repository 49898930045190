import { useStaticQuery, graphql } from "gatsby";

export const useHomePageInfoMsg = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(isFrontPage: { eq: true }) {
        homepageFields {
          informationalMessage
        }
      }
    }
  `);
  return data.wpPage.homepageFields.informationalMessage;
};
