import { useState, useEffect } from "react";

export const useTwitterIds = () => {
  const [hasError, setErrors] = useState(false);
  const [tweeterIds, setTweeterIds] = useState([]);

  async function fetchData() {
    const res = await fetch(`${process.env.GATSBY_TWITTER_FETCH_URL}`);
    res
      .json()
      .then((res) => setTweeterIds(res.body))
      .catch((err) => setErrors(err));
  }

  useEffect(() => {
    fetchData();
  }, []);

  return hasError ? hasError : tweeterIds;
};
