/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Spacer from "../../Spacer/Spacer";
import IsrLabel from "../../IsrLabel/IsrLabel";
import IsrButton from "../../IsrButton/IsrButton";
import "./FeaturedCrisis.scss";
import { useFeaturedCrisis } from "../../../hooks/useFeaturedCrisis/useFeaturedCrisis";

const FeaturedCrisis = () => {
  const { copy, headline, selectCrisis, showSubmitAProposal } =
    useFeaturedCrisis();

  const getCrisisStatusLabel = () => {
    if (selectCrisis && selectCrisis[0])
      switch (selectCrisis[0].crisis.crisisStatus) {
        case "Preparatory":
          return (
            <IsrLabel
              variant="hoverable"
              color="blue"
              size="md"
              hoverMessage={selectCrisis[0]?.crisis?.crisisStatusHoverText}
            >
              Preparatory
            </IsrLabel>
          );
        case "Declared":
          return (
            <IsrLabel
              variant="hoverable"
              color="pink"
              size="md"
              hoverMessage={selectCrisis[0]?.crisis?.crisisStatusHoverText}
            >
              Declared
            </IsrLabel>
          );
        case "Proposal submissions now closed":
          return (
            <IsrLabel
              variant="hoverable"
              color="grey"
              size="md"
              hoverMessage={selectCrisis[0]?.crisis?.crisisStatusHoverText}
            >
              Proposal submissions now closed
            </IsrLabel>
          );

        default:
          return null;
      }
    else return null;
  };
  return (
    <section className="featured-crisis">
      <div className="container">
        <Spacer size={100} mobileSize={50} />
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            {headline && <h2>{headline}</h2>}
            <Spacer size={20} mobileSize={12} />
            {copy && (
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: copy,
                }}
              />
            )}
            <Spacer size={50} mobileSize={30} />
            {selectCrisis && selectCrisis[0] && (
              <article>
                {selectCrisis[0].crisis?.headingSection?.heading && (
                  <h3>{selectCrisis[0].crisis?.headingSection?.heading}</h3>
                )}
                <Spacer size={20} mobileSize={10} />
                {getCrisisStatusLabel()}
                {selectCrisis[0].crisis?.headingSection?.backgroundImage && (
                  <GatsbyImage
                    image={
                      selectCrisis[0].crisis?.headingSection?.backgroundImage
                        .localFile?.childImageSharp?.gatsbyImageData
                    }
                    alt="featured crisis "
                  />
                )}
                <Spacer size={30} mobileSize={20} />
                <div className="featured-article-actions">
                  <div>
                    {showSubmitAProposal && (
                      <IsrButton variant="primary" size="sm">
                        {selectCrisis[0].uri && (
                          <Link
                            to="/submit-proposal-intermediary/"
                            title="Submit a proposal"
                          >
                            Submit a proposal
                          </Link>
                        )}
                      </IsrButton>
                    )}

                    <IsrButton variant="secondary" size="sm">
                      {selectCrisis[0].uri && (
                        <Link
                          to={selectCrisis[0].uri}
                          title={`Learn More - ${selectCrisis[0].crisis?.headingSection?.heading}`}
                        >
                          Learn More
                        </Link>
                      )}
                    </IsrButton>
                  </div>
                  {selectCrisis[0].uri && (
                    <Link
                      to={selectCrisis[0].uri}
                      title={`Learn More - ${selectCrisis[0].crisis?.headingSection?.heading}`}
                    >
                      <IsrButton variant="arrow" size="sm" />
                    </Link>
                  )}
                </div>
              </article>
            )}
          </div>
          <div className="col-md-2" />
        </div>
        <Spacer size={100} mobileSize={50} />
      </div>
    </section>
  );
};

export default FeaturedCrisis;
